// BillGates.js
import React from 'react';
import { Button, Flex, Text, Stack, Heading } from '@chakra-ui/react';

const BillGates = () => {
  return (
    <Flex height="calc(100vh - 74px)" align="center" justify="center">
      <Flex direction="column" align="center" width="200px">
        <Button
          as="a"
          href="tel:+15866007474"
          colorScheme="blue"
          size="lg"
          mb={4} // margin bottom to separate the button and questions
        >
          Call Bill Gates
        </Button>
        <Heading size="lg" mb={4}>Ask a Question</Heading>
        <Stack spacing={3} textAlign="left" align="flex-start">
          <Text>1. What inspired you to start Microsoft?</Text>
          <Text>2. What do you believe is the key to successful philanthropy?</Text>
          <Text>3. How do you envision the future of technology?</Text>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default BillGates;
