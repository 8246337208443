import React from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  VStack,
  CloseButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png'; // Importing the logo image

function Navbar() {
  // Determine if the current screen size is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Disclosure hook for managing the Drawer state on mobile
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Navigation links data
  const navLinks = [
    { name: 'David Dobrik', path: '/dobrik' },
    { name: 'Shaq', path: '/shaq' },
    { name: 'Gordon Ramsay', path: '/gordonramsay' },
    { name: 'Logan Paul', path: '/loganpaul' },
    { name: 'Bill Gates', path: '/billgates' },
    { name: 'Doughbriks', path: '/doughbriks' },
    { name: 'Contact', path: '/contact' },
  ];

  // Custom HamburgerIcon with increased size and color
  const CustomHamburgerIcon = () => (
    <HamburgerIcon w={6} h={6} color="#222222" />
  );

  return (
    <Box
      borderBottom="2px"
      borderColor="gray.200"
      py={4}
      bg="white"
      position="sticky"
      top="0"
      zIndex="1000"
    >
      <Flex
        maxW="600px"
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
        px={4}
      >
        {/* Left Spacer to keep the logo centered */}
        <Box flex="1" />

        {/* Centered Logo */}
        <Link to="/">
          <Image
            src={logo}
            alt="Logo"
            h={10}
            cursor="pointer"
            mx="auto"
          />
        </Link>

        {/* Right Side: Dropdown Menu on Desktop, Hamburger on Mobile */}
        <Box flex="1" display="flex" justifyContent="flex-end">
          {!isMobile ? (
            // Desktop Dropdown Menu
            <Menu placement="bottom-end">
              <MenuButton
                as={IconButton}
                icon={<CustomHamburgerIcon />}
                variant="outline"
                aria-label="Options"
                _hover={{ bg: 'gray.100' }}
                borderWidth="0px"
              />
              <MenuList mt={2} borderWidth="2px">
                {navLinks.map((link) => (
                  <MenuItem as={Link} to={link.path} key={link.name}>
                    {link.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            // Mobile Hamburger Icon that opens Drawer
            <>
              <IconButton
                icon={<CustomHamburgerIcon />}
                variant="outline"
                aria-label="Open Menu"
                onClick={onOpen}
                _hover={{ bg: 'gray.100' }}
                borderWidth="0px"
              />

              {/* Mobile Drawer */}
              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerHeader
                    borderBottomWidth="1px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Menu
                    <CloseButton onClick={onClose} />
                  </DrawerHeader>

                  <DrawerBody>
                    <VStack spacing={4} align="stretch" mt={4}>
                      {navLinks.map((link) => (
                        <Link
                          key={link.name}
                          to={link.path}
                          onClick={onClose}
                          style={{
                            padding: '10px 0',
                            borderBottom: '1px solid #e2e8f0',
                            color: '#222222', // Optional: Ensure link text is visible
                            textDecoration: 'none',
                          }}
                        >
                          {link.name}
                        </Link>
                      ))}
                    </VStack>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export default Navbar;
