import React from 'react';
import { Box, Text, Button, Flex, Image, Link as ChakraLink } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from '../assets/favicon.png';

function Footer() {
  return (
    <Box
      bg="white"
      color="gray.700"
      borderTop="2px"
      borderColor="gray.200"
      py={4}
      width="100%"
    >
      <Flex
        maxW="600px"
        mx="auto"
        alignItems="center"
        justifyContent="space-between"
        direction={{ base: 'column', md: 'row' }}
        py={10}
        textAlign={{ base: 'center', md: 'left' }}
      >
        {/* Logo with page reload */}
        <ChakraLink href="/">
          <Image
            src={logo}
            alt="Idols.chat Logo"
            h="40px"
            objectFit="contain"
            mb={{ base: 4, md: 0 }}
            order={{ base: 1, md: 1 }}
          />
        </ChakraLink>

        {/* Contact Button */}
        <Button
          as={Link}
          to="/contact"
          colorScheme="blue"
          variant="solid"
          size="sm"
          mb={{ base: 4, md: 0 }}
          order={{ base: 2, md: 3 }}
          borderRadius="full"
        >
          Contact
        </Button>

        {/* Copyright */}
        <Text fontSize="sm" order={{ base: 3, md: 2 }}>
          &copy; {new Date().getFullYear()} Idols.chat
        </Text>
      </Flex>
    </Box>
  );
}

export default Footer;
