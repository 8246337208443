// Shaq.js
import React from 'react';
import { Button, Flex, Text, Stack, Heading } from '@chakra-ui/react';

const Shaq = () => {
  return (
    <Flex height="calc(100vh - 74px)" align="center" justify="center">
      <Flex direction="column" align="center" width="200px">
        <Button
          as="a"
          href="tel:+19804009850"
          colorScheme="blue"
          size="lg"
          mb={4} // margin bottom to separate the button and questions
        >
          Call Shaq
        </Button>
        <Heading size="lg" mb={4}>Ask a Question</Heading>
        <Stack spacing={3} textAlign="left" align="flex-start">
          <Text>1. What was your favorite moment from your NBA career?</Text>
          <Text>2. Who was your biggest inspiration growing up?</Text>
          <Text>3. What’s one thing you wish you could tell your younger self?</Text>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Shaq;
