// GordonRamsay.js
import React from 'react';
import { Button, Flex, Text, Stack, Heading } from '@chakra-ui/react';

const GordonRamsay = () => {
  return (
    <Flex height="calc(100vh - 74px)" align="center" justify="center">
      <Flex direction="column" align="center" width="200px">
        <Button
          as="a"
          href="tel:+17074407533"
          colorScheme="blue"
          size="lg"
          mb={4} // margin bottom to separate the button and questions
        >
          Call Gordon Ramsay
        </Button>
        <Heading size="lg" mb={4}>Ask a Question</Heading>
        <Stack spacing={3} textAlign="left" align="flex-start">
          <Text>1. What’s your favorite dish to cook?</Text>
          <Text>2. What do you think is the most important skill for a chef?</Text>
          <Text>3. How do you handle kitchen disasters?</Text>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default GordonRamsay;
