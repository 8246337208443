import React from 'react';
import {
  Box,
  Container,
  VStack,
  Heading,
  Button,
  Text,
} from '@chakra-ui/react';

const Contact = () => {

  return (
    <Box height="calc(100vh - 74px)" py={12}>
      <Container maxW="6xl">
        <VStack spacing={4} textAlign="center" mb={12}>
          <Heading as="h1" size="xl">want voice?</Heading>
          <Text color="gray.600">lets get rolling</Text>
        </VStack>

        <VStack spacing={8}>
          {/* Contact for Mike Powers */}
          <Box
            p={8}
            boxShadow="base"
            borderRadius="lg"
            textAlign="center"
          >
            <Heading size="md">Mike Powers</Heading>
            <Button
              mt={4}
              colorScheme="blue"
              onClick={() => window.location.href = 'mailto:mike@hoo.be'}
              borderRadius="full"
            >
              mike@hoo.be
            </Button>
          </Box>

          {/* Contact for Nate Laquis */}
          <Box
            p={8}
            boxShadow="base"
            borderRadius="lg"
            textAlign="center"
          >
            <Heading size="md">Nate Laquis</Heading>
            <Button
              mt={4}
              colorScheme="blue"
              onClick={() => window.location.href = 'mailto:nate@hoo.be'}
              borderRadius="full"
            >
              nate@hoo.be
            </Button>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default Contact;
