import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Shaq from './components/people/Shaq';
import Dobrik from './components/people/Dobrik';
import Contact from './components/Contact';
import LoganPaul from './components/people/LoganPaul';
import GordonRamsay from './components/people/GordonRamsay';
import JackDoherty from './components/people/JackDoherty';
import BillGates from './components/people/BillGates';
import DB2 from './components/people/DB2';

const customTheme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
});

const Layout = ({ children }) => {
  const location = useLocation();
  const hideNavbar = location.pathname === '/doughbriks';

  return (
    <>
      {!hideNavbar && <Navbar />}
      {children}
      <Footer />
    </>
  );
};

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/shaq" element={<Shaq />} />
            <Route path="/dobrik" element={<Dobrik />} />
            <Route path="/loganpaul" element={<LoganPaul />} />
            <Route path="/jack" element={<JackDoherty />} />
            <Route path="/gordonramsay" element={<GordonRamsay />} />
            <Route path="/billgates" element={<BillGates />} />
            <Route path="/doughbriks" element={<DB2 />} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  );
}

export default App;
