// src/pages/Home.jsx

import React from 'react';
import {
  Box,
  Flex,
  Image,
  Heading,
  Button,
  Text,
  VStack,
  Stack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import doughbriksPizzaLogo from './doughbrikspizza.png';
import pizzaImage from './pizza.png';

const DB2 = () => {
  return (
    <Flex
      direction="column"
      minHeight="100vh"
      bg="white"
      overflowX="hidden"
    >
      {/* Header */}
      <Flex
        as="header"
        width="100%"
        padding="20px"
        alignItems="center"
        justifyContent="center" // Center the logo horizontally
      >
        <Link to="/"> {/* Wrap Image with Link */}
          <Image
            src={doughbriksPizzaLogo}
            alt="Doughbriks Pizza Logo"
            width={{ base: '350px', md: '400px', lg: '450px' }} // Responsive widths
            height="auto"
            objectFit="contain" // Ensure the image fits within its container
            cursor="pointer" // Change cursor to pointer on hover
          />
        </Link>
      </Flex>

      {/* Main Content */}
      <Flex
        as="main"
        direction="column"
        align="center"
        justify="center" // Centers content vertically
        flex="1" // Takes up available space
        paddingTop={{ base: '40px', md: '60px' }} // Vertical padding
        position="relative"
      >
        <Heading
          as="h1"
          color="black"
          fontSize={{ base: '24px', md: '5vw', lg: '48px' }}
          fontFamily="'Microsoft Sans Serif', sans-serif"
          fontWeight="400"
          textAlign="center"
          mt="20px"
          lineHeight="1.2" // Improves readability
        >
          ORDER A PIZZA FROM <br /> DAVID
        </Heading>
        
        {/* Updated CALL NOW Button */}
        <Button
          as="a" // Render as an anchor tag
          href="tel:+12058908856" // Telephone link
          mt="20px"
          px="20px"
          py="10px"
          bg="#EF4B41"
          color="white"
          borderRadius="36px"
          fontSize={{ base: '16px', md: '4vw', lg: '24px' }}
          fontFamily="'Microsoft Sans Serif', sans-serif"
          fontWeight="400"
          border="none"
          cursor="pointer"
          _hover={{ bg: '#d43a38' }}
          boxShadow="md" // Optional: Add shadow to the button
          transition="background 0.3s ease" // Smooth hover transition
        >
          CALL NOW
        </Button>
        
        <Box
          width="100%"
          mt="20px"
          zIndex="1"
          display="flex"
          justifyContent="center" // Centers the pizza image
          overflow="hidden" // Prevents the image from overflowing
        >
          <Image
            src={pizzaImage}
            alt="Delicious Pizza"
            width={{ base: '90vw', md: '80vw', lg: '60vw' }}
            height="auto"
            display="block"
            mx="auto"
            position="relative" // Ensure relative positioning
          />
        </Box>
      </Flex>

      {/* Footer */}
      <Box
        as="footer"
        width="100%"
        bg="#114B4B"
        py="40px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        zIndex="2"
      >
        {/* Footer Info */}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          justify="center"
          align="center"
          spacing={{ base: '20px', md: '40px' }}
          width="100%"
          px="20px" // Optional: Add horizontal padding
        >
          {/* Hours Information */}
          <VStack spacing="22px" mb={{ base: '20px', md: '0' }}>
            <Text
              color="#F9F3D0"
              fontSize={{ base: '18px', md: '3vw', lg: '24px' }}
              fontFamily="'Microsoft Sans Serif', sans-serif"
              fontWeight="400"
              textAlign="center"
            >
              HOURS OF OPERATION
            </Text>
            <Text
              color="#F9F3D0"
              fontSize={{ base: '12px', md: '2vw', lg: '15px' }}
              fontFamily="'Microsoft Sans Serif', sans-serif"
              fontWeight="400"
              textAlign="center"
              whiteSpace="pre-line"
            >
              Sun-Wed 11AM - 10PM{'\n'}Thu 11AM - 12AM{'\n'}Fri-Sat 11AM - 3AM
            </Text>
          </VStack>

          {/* Contact Information */}
          <VStack spacing="22px" mb={{ base: '20px', md: '0' }}>
            <Text
              color="#EF4B41"
              fontSize={{ base: '20px', md: '3vw', lg: '26px' }}
              fontFamily="'Microsoft Sans Serif', sans-serif"
              fontWeight="400"
              textAlign="center"
            >
              DOUGHBRIKS PIZZA
            </Text>
            <Text
              color="#EF4B41"
              fontSize={{ base: '12px', md: '2vw', lg: '15px' }}
              fontFamily="'Microsoft Sans Serif', sans-serif"
              fontWeight="400"
              textAlign="center"
              whiteSpace="pre-line"
            >
              8363 Sunset Blvd{'\n'}West Hollywood, CA 90069
            </Text>
          </VStack>
        </Stack>

        {/* Email Address */}
        <Text
          color="#F9F3D0"
          fontSize={{ base: '16px', md: '2.5vw', lg: '20px' }}
          fontFamily="'Microsoft Sans Serif', sans-serif"
          fontWeight="400"
          textAlign="center"
          mt="20px"
        >
          pizza@doughbriks.com
        </Text>
      </Box>
    </Flex>
  );
};

export default DB2;
