import React from 'react';
import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'; // Import and alias

const Home = () => {
  return (
    <Container maxW="3xl" height="calc(100vh - 74px)">
      <Stack
        as={Box}
        textAlign="center"
        spacing={{ base: 6, md: 10 }}
        pt={{ base: 24, md: 36 }}
        pb={{ base: 18, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '4xl', sm: '5xl', md: '6xl' }}
          lineHeight="140%"
        >
          engage your fans <br />
          <Text as="span" color="blue.400">
            with voice tech
          </Text>
        </Heading>
        <Text color="gray.500">
          realtime ai voice agents to engage your audience. <br /> engage. personalize. sell. at scale.
        </Text>
        <Stack
          direction="column"
          spacing={3}
          align="center"
          alignSelf="center"
          position="relative"
        >
          <Button
            colorScheme="blue"
            rounded="full"
            size="lg"
            px={6}
            as={RouterLink} // Render Button as RouterLink
            to="/dobrik"    // Use 'to' prop for routing
          >
            try it now
          </Button>
          <Link
            as={RouterLink} // Render Link as RouterLink
            to="/contact"    // Use 'to' prop for routing
          >
            contact
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
};

export default Home;
