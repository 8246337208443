// GordonRamsay.js
import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

const JackDoherty = () => {
  return (
    <Flex height="calc(100vh - 74px)" align="center" justify="center">
      <Button
        as="a"
        href="tel:+16015394498"
        colorScheme="blue"
        size="lg"
      >
        Call Jack Doherty
      </Button>
    </Flex>
  );
};

export default JackDoherty;
