// Dobrik.js
import React from 'react';
import { Button, Flex, Text, Stack, Heading } from '@chakra-ui/react';

const Dobrik = () => { 
  return (
    <Flex height="calc(100vh - 74px)" align="center" justify="center">
      <Flex direction="column" align="center" width="200px">
        <Button
          as="a"
          href="tel:+13364446150"
          colorScheme="blue"
          size="lg"
          mb={4} // margin bottom to separate the button and questions
        >
          Call David Dobrik
        </Button>
        <Heading size="lg" mb={4}>Ask a Question</Heading>
        <Stack spacing={3} textAlign="left" align="flex-start">
          <Text>1. What inspired you to start vlogging?</Text>
          <Text>2. How do you come up with ideas for your videos?</Text>
          <Text>3. What’s your favorite memory from your YouTube journey?</Text>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default Dobrik;
